import { AngularEnvironmentModel } from 'src/app/shared/models/generic.models';

export const environment: AngularEnvironmentModel = {
  production: false,
  beta: true,
  qa: false,
  marketplaceOneUI: 'https://beta.marketplace-one.figdevteam.com/#',
  iComplyUI: 'https://demo.icomply.io/#',
  truelyticsSsoUrl: 'https://app.truelytics.com/user/sso?auto=true&id=',
  aspidaSso: 'https://sso.aspida.com/',
  equitrustAppLink: 'https://agents.equitrust.com/',
  amplitudeV1ApiKey: '', // TODO: Remove when finish migration to backend
  amplitudeV2ApiKey: 'a53c801165de68c01f3853619cefc50f'
};
