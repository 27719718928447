<section class="flex flex-col h-full bg-top bg-no-repeat bg-cover bg-gray-wave">
  <section class="flex flex-col w-full mx-auto max-w-1280" id="exploreTop">
    @if (!loading) {
      @if (!error.hasError) {
        <router-outlet (activate)="onViewLoaded($event)"></router-outlet>
        <button type="button" (click)="scrollTo('exploreTop')" class="fixed bottom-0 right-0 m-20 rounded-full md:m-40 icon-button is-primary is-lg" mat-button matTooltip="Back to top">
          <i class="material-icons">north</i>
        </button>
      }
      @else {
        <app-message-notification [panelConfig]="messagePanelConfig">{{error.message}}</app-message-notification>
      }
    }
    @else {
      <lottie-player class="w-full h-auto" [@fadeInOut]
        [src]="loadingAnimationScriptUrl"
        background="transparent"
        loop autoplay>
      </lottie-player>
    }
  </section>
</section>